import {ModuleWithProviders}  from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RouteGuardService} from "../../services/route-guard.service";

/**
 * IMPORTANT:
 * Do NOT forget to add a RegEx for the route to "FlexMvc/Helpers/RouteEval.Helper.cs"
 * Do NOT forget to test it!!!
 */
export const routes: Routes = [
    {path: '', loadChildren: '../login-route/login-route.module#LoginRouteModule', pathMatch: 'full'},
    {path: 'pa', loadChildren: '../pre-auth/pre-auth.module#PreAuthModule'},
    {path: 'aktivace', loadChildren: '../register-b2c/register-b2c.module#RegisterB2CModule'},
    {path: 'upozorneni', loadChildren: '../warnings/warnings.module#WarningsModule'},
    {path: 'tvorba-nakupnich-cen', data:{code:'create-shopping-price'}, loadChildren: '../create-shopping-prices/create-shopping-prices.module#CreateShoppingPricesModule', canActivate: [RouteGuardService]},
    {path: 'tvorba-cref-cen', data:{code:'create-cref-prices'}, loadChildren: '../create-cref-prices/create-cref-prices.module#CreateCrefPricesModule', canActivate: [RouteGuardService]},
    {path: 'tvorba-prodejnich-cen', data:{code:'create-selling-prices'}, loadChildren: '../create-selling-prices/create-selling-prices.module#CreateSellingPricesModule', canActivate: [RouteGuardService]},
    {path: 'nastaveni-prodejnich-cen', data:{code:'setting-selling-prices'}, loadChildren: '../setting-selling-prices/setting-selling-prices.module#SettingSellingPricesModule', canActivate: [RouteGuardService]},
    {path: 'administrace-zamestnancu', data:{code:'administration-employees'}, loadChildren: '../administration-employees/administration-employees.module#AdministrationEmployeesModule', canActivate: [RouteGuardService]},
    {path: 'zapomenute-heslo', loadChildren: '../forgotten-password/forgotten-password.module#ForgottenPasswordModule'},
    {path: 'vysledky-vyhledavani', loadChildren: '../search-result/search-result.module#SearchResultModule'},
    //{path: 'vysledky-vyhledavani', loadChildren: 'app/modules/luigi-search/luigi-search.module#LuigiSearchModule'},
    {path: 'chyba', loadChildren: '../warnings/warnings.module#WarningsModule'},
    // preparing GP WebPay routes
    // {path: 'gpwebpay', loadChildren: 'app/modules/cart/cart.module#CartModule'},
    {path: 'prihlasit', loadChildren: '../login-route/login-route.module#LoginRouteModule'},
    // DO NOT ADD THIS TO "FlexMvc/Helpers/RouteEval.Helper.cs"
    {path: '**', redirectTo: '/chyba/404'}
];

export const appRoutingProviders: any[] = [
    RouteGuardService
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
