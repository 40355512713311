import  {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class CategoryFilterService {
    constructor(
        private http: HttpClient
    ) {}

    GetCategoriesFilter(): Observable<any[]>{
        return this.http.get<any[]>('api/category/get-filter');
    }
}