/**
 * Component providing functionality of a full article detail.
 */
import {Component, Input} from '@angular/core';
import {Translatable, ArticleSelector, PagedResponse} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Component({
    templateUrl: '../../tpl/article-list-news-index.html',
    selector: 'cmp-article-list-index'
})

export class ArticleListNewsIndexComponent extends Translatable {

    @Input() articleSectionId: number;
    @Input() pageSize: number;
    articleImagePathPrefix: string;

    articles: ArticleSelector[];
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, seSvc: SettingsService, private http: HttpClient) {
        super(dataSvc, seSvc);

        this.articleImagePathPrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/artnw/images/';
    }

    getNews(): void {
        this.http.get<PagedResponse<ArticleSelector>>(`api/article/getArticleSectionChildrenPaged/${this.articleSectionId}/1/${this.pageSize}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                res => {
                    this.articles = res.data;
                    this.articles.forEach((elem: ArticleSelector) => {
                        elem.imagePath = elem.imagePath ? this.articleImagePathPrefix + elem.imagePath : null;
                    });
                }
            );
    }

    ngOnInit(): void {
        this.getNews();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}