/**
 * @description
 * Confirm password validation directive.
 * Can be used generally for comparing two strings - makes control invalid when strings are not the same
 * One string is taken from the host element, the other supplied via input parameter.
 */
import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators} from "@angular/forms";

export function compareValidator(compareWith: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
        const self: string = control.value ? control.value : null;
        let no: boolean = self !== compareWith;
        return no ? {'compare': {self}} : null;
    };
}

@Directive({
    selector: '[compare]',
    providers: [{provide: NG_VALIDATORS, useExisting: CompareValidatorDirective, multi: true}]
})

export class CompareValidatorDirective implements Validator, OnChanges {

    @Input('compare') compare: string;
    private valFn = Validators.nullValidator;

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes['compare'];
        if (change) {
            let val = change.currentValue;
            this.valFn = compareValidator(val);
        } else {
            this.valFn = Validators.nullValidator;
        }
    }

    validate(control: AbstractControl): { [key: string]: any } {
        return this.valFn(control);
    }

}