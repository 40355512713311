/**
 * Feature module for {@link CartIndicatorComponent}.
 */
import {NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {CartIndicatorComponent} from "./cart-indicator.component";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";


@NgModule({
    imports: [
        CommonModule,
        ImageCacheBustModule,
        RouterModule
    ],
    declarations: [CartIndicatorComponent],
    exports: [CartIndicatorComponent]
})

export class CartIndicatorModule {}
