import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {IPricelist} from "../common";


@Injectable()
export class PricelistService {
    constructor(
        private http: HttpClient
    ) {
    }
    GetPricelists(): Observable<IPricelist[]> {
        return this.http.get<IPricelist[]>(`api/pricelist/get-pricelists`);
    }

    GetPricelistsBySalesman(): Observable<IPricelist[]> {
        return this.http.get<IPricelist[]>('api/pricelist/get-pricelists-by-salesman');
    }

}