/**
 * Panel providing filtering by product flags, see the {@link SharedProductSettings} class, the {@Link Flags} property.
 * For instance flags are preserved across category navigation and cultureId changes
 */
import {Component, EventEmitter, Output, ElementRef, ViewChild} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {fromEvent, Subject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";

@Component({
    selector: 'cmp-product-search',
    templateUrl: '../../tpl/product-search.html'
})

export class ProductSearchComponent extends Translatable {

    @Output() onSearch: EventEmitter<string>;
    @ViewChild('phrases') phrases: ElementRef;

    phrase: string;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        super(dataSvc, seSvc);

        this.onSearch = new EventEmitter();

    }

    Search(): void {
        if (this.phrase.length > 2 || this.phrase.length == 0) {
            this.onSearch.emit(this.phrase);
        }
    }

    ngAfterViewInit(): void {
        this.BindSearch();
    }

    private BindSearch(): void {
        const search = this.phrases.nativeElement;
        fromEvent(search, 'input')
            .pipe(
                debounceTime(333),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(() =>{
                this.Search();
            });
    }


}