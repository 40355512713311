import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {IOrganization} from "../common";


@Injectable()
export class OrganizationService {
    constructor(
        private http: HttpClient
    ) {
    }
    GetOrganizations(): Observable<IOrganization[]> {
        return this.http.get<IOrganization[]>(`api/organization/get-organizations`);
    }

    GetOrganizationsBySalesman(): Observable<IOrganization[]> {
        return this.http.get<IOrganization[]>(`api/organization/get-organizations-by-salesman`);
    }

}