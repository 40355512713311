// Digest realm="FlexRealm", nonce="db34cb20d445e9639e1f36f55aa95376", qop="auth"

declare let StringView: any;

export class KeyValuePair {

    key: string;
    value: string;

    constructor(k: string, v: string) {
        this.key = k;
        this.value = v;
    }
}

export function parseMultiPartString(digest: string): Array<KeyValuePair> {

    digest = digest.replace(/Digest /i, '').replace(/\s*/g, '');

    let kvpArray = digest.split(',');
    let result: Array<KeyValuePair> = [];

    function fillResult(v: string, a: Array<KeyValuePair>) {
        let tmp = v.split('=');
        a.push(new KeyValuePair(tmp[0], tmp[1].replace(/"/g, '')))
    }

    kvpArray.forEach(v => fillResult(v, result));

    return result;
}

export function isRouteSecured(theRoute: string): boolean {
    return /^\/muj-ucet.*/.test(theRoute);
}

export function stripHtml(input: string): string {
    if (!input) {
        return null;
    }
    const regex = /(<([^>]+)>)/ig;
    return input.replace(regex, '');
}

export function ToBase64String(input: any): string {
    return new StringView(JSON.stringify(input)).toBase64();
}