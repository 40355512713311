import {EventEmitter, Injectable} from "@angular/core";
import {ProductVariantBriefSelector, IWishList} from "../interfaces/general";
import {DigestService} from "./digest.service";
import {HttpClient} from "@angular/common/http";

declare let StringView: any;

@Injectable()
export class WishListService {

    private _wishLists: IWishList[];
    onWishListsChanged: EventEmitter<IWishList[]>;

    get wishLists(): IWishList[] {
        return this._wishLists;
    }

    constructor(private digestSvc: DigestService, private http: HttpClient) {
        this.onWishListsChanged = new EventEmitter<IWishList[]>();

        this._wishLists = null;
        if (this.digestSvc.userName) {
            this.getWishLists();
        }
    }

    private getWishLists(): void {
        let url = 'api/wish-lists/wish-lists-for-user';
        this.http.get<IWishList[]>(url)
            .subscribe(res => {
                this._wishLists = res;
                this.onWishListsChanged.emit(this._wishLists);
            });
    }

    createNewList(name: string): void {
        let url = 'api/wish-lists/add-new';
        let newListNameBase64 = new StringView(name).toBase64();

        this.http.get<IWishList[]>(`${url}/${newListNameBase64}`)
            .subscribe(res => {
                this._wishLists = res;
                this.onWishListsChanged.emit(this._wishLists);
            });
    }

    deleteWishList(hash: string): void {
        let url = `api/wish-lists/delete/${hash}`;
        this.http.get<boolean>(url)
            .subscribe(res => {
                if (res) {
                    this.getWishLists();
                }
            })
    }

    addItemToWishList(wishListHash: string, variantId: number): EventEmitter<ProductVariantBriefSelector[]> {

        let emitter = new EventEmitter<ProductVariantBriefSelector[]>();
        let url = `api/wish-lists/add-item/${wishListHash}/${variantId}`;


        this.http.get<IWishList>(url)
            .subscribe(res => {
                let filtered = this._wishLists.filter(wl => wl.hash == wishListHash);
                if (filtered) {
                    let filteredList = filtered[0];
                    if (filteredList && res.variants) {
                        filteredList.variantIds = res.variants.map(p => p.id);
                    }
                }
                emitter.emit(res.variants);
            });

        return emitter;
    }

    removeFromWishList(wishListHash: string, variantId: number): EventEmitter<ProductVariantBriefSelector[]> {

        let emitter = new EventEmitter<ProductVariantBriefSelector[]>();
        let url = `api/wish-lists/remove-item/${wishListHash}/${variantId}`;

        this.http.get<IWishList>(url)
            .subscribe(res => {
                let filtered = this._wishLists.filter(wl => wl.hash == wishListHash);
                if (filtered) {
                    let filteredList = filtered[0];
                    if (filteredList && res.variants) {
                        filteredList.variantIds = res.variants.map(p => p.id);
                    }
                }
                emitter.emit(res.variants);
            });
        return emitter;
    }


    getWishList(wishListHash: string): EventEmitter<IWishList> {

        let emitter = new EventEmitter<IWishList>();
        let url = `api/wish-lists/${wishListHash}`;

        this.http.get<IWishList>(url)
            .subscribe(res => {
                emitter.emit(res);
            });
        return emitter;
    }

    isOnList(wishListHash: string, productId: number): boolean {
        if (!this._wishLists) {
            return false;
        }

        let filteredLists = this._wishLists.filter(wl => wl.hash == wishListHash);
        if (!filteredLists) {
            return false;
        }

        let list = filteredLists[0];
        if (!list || !list.variantIds) {
            return false;
        }

        let filtered = list.variantIds.filter(id => id == productId);
        if (!filtered) {
            return false;
        }

        return filtered[0] && filtered[0] == productId;
    }

}