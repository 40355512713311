import {Component} from '@angular/core';
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {Translatable} from "../../interfaces/general";

@Component({
    selector:'cmp-growl',
    templateUrl: '../../tpl/growl.html'
})

export class GrowlComponent extends Translatable {

    classModifier: string;
    message: string;
    show: boolean = false;
    constructor(dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);

    }

    ngOnInit(): void {
    }

    ShowMessage(classModifier: string, message: string){
        this.show = true;
        this.classModifier = classModifier;
        this.message = message;

        setTimeout(()=>{
            this.show = false;
        },3000);
    }
}
