
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule} from "@angular/forms";
import {ProductSearchComponent} from "./product-search.component";



@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],

    declarations: [
        ProductSearchComponent
    ],
    exports: [
        ProductSearchComponent
    ]
})

export class ProductSearchModule {
}
