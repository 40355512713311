import {EventEmitter, Injectable} from "@angular/core";



@Injectable({providedIn:'root'})
export class ScheduleModalService {
    constructor(
    ) {
    }

    OnPlanWrite: EventEmitter<any> = new EventEmitter<any>();

}