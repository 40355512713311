import {Directive, ElementRef, HostListener, Renderer2} from "@angular/core";

@Directive({
    selector: '[noAutoComplete]'
})

export class NoAutocompleteDirective {

    @HostListener('mouseenter') undoReadOnly() {
        if (!this.readOnlyUnDone) {
            this.rend.removeAttribute(this.elRef.nativeElement, 'readonly');
            this.rend.setAttribute(this.elRef.nativeElement, 'autocomplete', 'off');
            this.readOnlyUnDone = true;
        }

    }
    private readOnlyUnDone: boolean = false;

    constructor (private elRef: ElementRef, private rend: Renderer2) {
        this.rend.setAttribute(this.elRef.nativeElement, 'readonly', '');
    }
}