/**
 * Module containing all features related to login:
 * - LoginComponent with the form for entering user credentials
 * - SignOutComponent - for signing user out
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";
import {GrowlComponent} from "./growl.component";




@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule
    ],

    declarations: [
        GrowlComponent
    ],
    exports: [GrowlComponent]
})

export class GrowlModule {
}
