import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector, Type
} from "@angular/core";
import {GeneralDialogComponent} from "./general-dialog.component";
import {GeneralDialogInjector, GeneralDialogRef} from "./common";
import {GeneralDialogConfig} from "./general-dialog-config";

@Injectable()
export class GeneralDialogService {
    dialogComponentRef: ComponentRef<GeneralDialogComponent>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {
    }

    private appendDialogComponentToBody(config: GeneralDialogConfig): GeneralDialogRef {
        const map = new WeakMap();
        map.set(GeneralDialogConfig, config);

        const dialogRef = new GeneralDialogRef();
        map.set(GeneralDialogRef, dialogRef);

        const sub = dialogRef.afterClosed.subscribe(() => {
            // close the dialog
            this.removeDialogComponentFromBody();
            sub.unsubscribe();
        });

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GeneralDialogComponent);
        const componentRef = componentFactory.create(new GeneralDialogInjector(this.injector, map));

        if(config && config.cssClassModifier){
            componentRef.instance.cssClassModifier = config.cssClassModifier;
        }
        if(config && config.title){
            componentRef.instance.title = config.title;
        }

        this.appRef.attachView(componentRef.hostView);
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        this.dialogComponentRef = componentRef;

        this.dialogComponentRef.instance.onClose.subscribe(() => {
            this.removeDialogComponentFromBody();
        });

        return dialogRef;
    }

    private removeDialogComponentFromBody() {
        this.appRef.detachView(this.dialogComponentRef.hostView);
        this.dialogComponentRef.destroy();
    }

    public open(componentType: Type<any>, config: GeneralDialogConfig): GeneralDialogRef {
        if (!config) {
            config = GeneralDialogConfig.Empty;
        }
        const dialogRef = this.appendDialogComponentToBody(config);

        this.dialogComponentRef.instance.childComponentType = componentType;

        return dialogRef;
    }

    public close(): void {
        this.removeDialogComponentFromBody();
    }
}
