import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {DigestService} from "../services/digest.service";


@Directive({
    selector: '[writersOnly]'
})

export class WritersOnlyDirective {

    @Input() set writersOnly(val: boolean) {
        if (val && this.digestSvc.currentRight > 1) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    constructor (private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef,
                 private digestSvc: DigestService) {

    }
}