import {ApplicationRef, ComponentFactoryResolver, Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralDialogComponent} from "./general-dialog.component";
import {GeneralDialogInsertionDirective} from "./general-dialog-insertion.directive";
import {GeneralDialogService} from "./general-dialog.service";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [GeneralDialogComponent, GeneralDialogInsertionDirective],
    entryComponents: [GeneralDialogComponent],
    providers: [
        {
            provide: GeneralDialogService,
            useClass: GeneralDialogService,
            deps: [ComponentFactoryResolver, ApplicationRef, Injector]
        }
    ]
})

export class GeneralDialogModule {
}
