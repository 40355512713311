import {EventEmitter, Injectable} from "@angular/core";



@Injectable()
export class GeneralConfirmModalService {
    constructor(
    ) {
    }

    OnConfirm: EventEmitter<boolean> = new EventEmitter<boolean>();
    message: string;

}