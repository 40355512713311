
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GrowlModule} from "../growl/growl.module";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";
import {CopySellingPricesModalComponent} from "./copy-selling-prices-modal.component";
import {ValidationModule} from "../validation/validation.module";




@NgModule({
    imports: [
        CommonModule,
        GeneralDialogModule,
        ReactiveFormsModule,
        FormsModule,
        GrowlModule,
        ValidationModule
    ],
    declarations: [CopySellingPricesModalComponent],
    providers: [],

    exports:[]
})

export class CopySellingPricesModalModule {
}