import {InjectFlags, InjectionToken, Injector, Type} from "@angular/core";
import {Observable, Subject} from "rxjs";

export class GeneralDialogInjector implements Injector {
    constructor(
        private _parentInjector: Injector,
        private _additionalTokens: WeakMap<any, any>
    ) {}

    get<T>(
        token: Type<T> | InjectionToken<T>,
        notFoundValue?: T,
        flags?: InjectFlags
    ): T;
    get(token: any, notFoundValue?: any):any;
    get(token: any, notFoundValue?: any, flags?: any) {
        if(flags){}//to prevent "'flags' is declared but its value is never read" error
        
        const value = this._additionalTokens.get(token);
        const parent = this._parentInjector.get<any>(token, notFoundValue);

        if (value) return value;

        return parent;
    }
}

export class GeneralDialogRef {

    constructor() {}

    close(result?: any) {
        this._afterClosed.next(result);
    }

    private readonly _afterClosed = new Subject<any>();
    afterClosed: Observable<any> = this._afterClosed.asObservable();
}
