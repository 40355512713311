/**
 * @description
 * Serves as a container for front end application settings
 * Place only things that are really needed
 */
import {Injectable} from '@angular/core';
import {
    Culture,
    StringIndexedObject,
    SettingItem,
    Settings,
    GaSelector,
    SeznamSelector,
    HeurekaSelector} from "../interfaces/general";
import {SharedAppSettings} from "../shared-settings/shared-settings";
import {loadFromLocalStorage, loadFromSession, saveToSession} from "../helpers/cookie.helper";
import {IntegrationService} from "../interceptors/integration.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, tap} from "rxjs/operators";

let settings: Settings =
    {
        preAuth: false,
        preAuthToLocal: true,
        preAuthLocalExpiration: 12,
        cartAdjustAction: "ask",
        pageSizes: [{value: 12}, {value: 24}, {value: 36}],
        countries: [
            {
                id: 326,
                name: 'Česká republika'
            },
            {
                id: 462,
                name: 'Slovenská republika'
            }
        ],
        cultures: [
            {
                code: "cs-CZ",
                cultureId: 34,
                name: "Česky",
                currencyCode: "CZK",
                currencySymbol: "Kč",
                translationKey: "app-czech",
                htmlLangAttribute: "cs",
                cultureBoundDomain: "https://www.flex.scdev.cz"
            },
            {
                code: "sk-SK",
                name: "Slovensky",
                currencyCode: "EUR",
                currencySymbol: "€",
                cultureId: 102,
                translationKey: "app-slovak",
                htmlLangAttribute: "sk",
                cultureBoundDomain: "https://www.flex.scdev.cz"
            },
            {
                code: "en-US",
                name: "English",
                currencyCode: "USD",
                currencySymbol: "$",
                cultureId: 50,
                translationKey: "app-english",
                htmlLangAttribute: "en",
                cultureBoundDomain: "https://flex.scdev.cz"
            }
        ],
        validationPatterns: {
            email: /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[_a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,8})$/,
            naturalNumber: /^[1-9][0-9]*$/,
            decimalNumber: /^(\d*[.,])?\d+$/,
            formattedNaturalNumber: /^(\d{1,3} (\d{3} )*\d{3}|\d{1,3})$|^[1-9][0-9]*$/,
            phone: {
                34: /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                102: /^(\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                50: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/
            },
            zipCodeCz: /^\d{3}\s?\d{2}$/
        },
        imageServerPathPrefix: "https://img.flex.scdev.cz",
        localDomain: "http://localhost/Flex",
        currentServerUrl: "",
        decimalSettings: null,
        currencies: {
            1: "$",
            2: "€",
            3: "Kč"
        },
        currencyCodes: {
            1: "USD",
            2: "EUR",
            3: "CZK"
        },
        shopSeo: null,
        routesWithDefaultSeo: [/^$/, /^\/$/],
        assetPathPrefix: 'app/common/classic'
    };

export function GetLocaleFactory(seSvc: SettingsService) {
    return seSvc.getLocale()
}

@Injectable()
export class SettingsService {

    private _cacheBustKey: string;
    get cacheBustKey(): string {
        return this._cacheBustKey;
    }

    set cacheBustKey(value: string) {
        this._cacheBustKey = value;
    }

    private _availabilitySetting:AvailabilitySetting;
    get availabilitySetting(): AvailabilitySetting{
        return this._availabilitySetting;
    }

    set availabilitySetting(value: AvailabilitySetting) {
        this._availabilitySetting = value;
    }

    private _sen: StringIndexedObject;
    get sen(): StringIndexedObject {
        return this._sen;
    }

    set sen(value: StringIndexedObject) {
        this._sen = value;
    }

    private _culture: Culture;
    get culture(): Culture {
        return this._culture;
    }

    set culture(value: Culture) {
        this._culture = value;
    }

    private _gaSettings: GaSelector;
    get gaSettings(): GaSelector {
        return this._gaSettings;
    }

    set gaSettings(value: GaSelector) {
        this._gaSettings = value;
    }

    private _seznamSettings: SeznamSelector;
    get seznamSettings(): SeznamSelector {
        return this._seznamSettings;
    }

    set seznamSettings(value: SeznamSelector) {
        this._seznamSettings = value;
    }

    private _heurekaSettings: HeurekaSelector;
    get heurekaSettings(): HeurekaSelector {
        return this._heurekaSettings;
    }

    set heurekaSettings(value: HeurekaSelector) {
        this._heurekaSettings = value;
    }

    settings: Settings;
    sharedAppDefaults: StringIndexedObject;
    currencyId: number;
    defCulture: Culture;
    defCurrency: number;
    comAllowed: boolean = false;

    private _modalOpen: boolean = false;
    get modalOpen(): boolean {
        return this._modalOpen;
    }

    set modalOpen(value: boolean) {
        this._modalOpen = value;
    }

    constructor(saSvc: SharedAppSettings, private http: HttpClient, private iSvc: IntegrationService) {

        this.settings = settings;
        this.sharedAppDefaults = saSvc.Defaults;

        // Culture and currency are retrieved from the shared application settings
        // and valid either until user logs in or changes them manually
        this.defCulture = this.settings.cultures.filter((c) => c.cultureId == this.sharedAppDefaults['CultureId'])[0];
        this.defCurrency = this.sharedAppDefaults['CurrencyId'];

        let cult = <Culture>loadFromSession('cult');
        if (cult) {

            this.culture = cult;
        } else {
            this.culture = this.defCulture;
            saveToSession('cult', this.culture)
        }
        let curr = loadFromSession('curr');
        if (curr) {
            this.currencyId = curr;
        } else {
            this.currencyId = this.defCurrency;
            saveToSession('curr', this.currencyId)
        }

        SettingsService.setHtmlLangAttribute(this.culture.htmlLangAttribute);
    }

    setCulture(cultureId: number, currencyId?: number) {
        this.setCultureAndReload(false, cultureId, currencyId);
    }

    setCultureAndReload(reload: boolean, cultureId: number, currencyId?: number) {

        this.culture = this.settings.cultures.filter(c => c.cultureId == cultureId)[0];
        saveToSession('cult', this.culture);

        if (currencyId) {
            this.currencyId = currencyId;
            saveToSession('curr', this.currencyId);
        }

        SettingsService.setHtmlLangAttribute(this.culture.htmlLangAttribute);

        if (reload) location.reload();
    }

    private setStartUpSettings(settingMap: Map<string, any>): void {

        this.settings.imageServerPathPrefix = settingMap.get('ImageServer');
        this.settings.currentServerUrl = settingMap.get('ServerUrl');
        this.settings.preAuth = settingMap.get('PreAuthEnabled') === "true";
        this.settings.decimalSettings = JSON.parse(settingMap.get('DecimalSettings'));
        this.settings.shopSeo = JSON.parse(settingMap.get('ShopSeoSettings'))[this.culture.cultureId];
        this.settings.assetPathPrefix = settingMap.get('AssetPathPrefix');
        this.availabilitySetting = settingMap.get('Availability');
        this.cacheBustKey = settingMap.get('ImageCacheBust');
        this.gaSettings = settingMap.get('GaSettings');
        this.seznamSettings = settingMap.get('SeznamSettings');
        this.heurekaSettings = settingMap.get('HeurekaSettings');

        let pa = this.settings.preAuthToLocal ? loadFromLocalStorage('pa') : loadFromSession('pa');
        this.comAllowed = pa || !this.settings.preAuth;
        this.setCurrentServerUrl();
    }

    private setUpApplication(input: any): any {
        const m = SettingsService.makeSettingsMap(input['StartUpSettings']);
        this.setStartUpSettings(m);
        return this.makeSentenceObject(input['Translations']);
    }

    getAllTranslations(): any {

        let options = {headers: new HttpHeaders()};
        options.headers.set('F-CC', `cu:${this.culture.cultureId},cy:${this.currencyId}`);

        this.iSvc.httpRegional = {
            comAllowed: this.comAllowed,
            cultureId: this.culture.cultureId,
            currencyId: this.currencyId
        };

        let result = this.http.get(`api/lang/${this.culture.cultureId}`, options)
            .pipe(
                map(res => this.setUpApplication(res)),
                tap(res => {
                    this.sen = res;
                    this.iSvc.httpRegional.comAllowed = this.comAllowed;
                })
            );
        return result.toPromise()
    }

    getLocale(): string {
        return this.culture.code;
    }

    private makeSentenceObject(input: Object[]): Object {
        let tmp: StringIndexedObject = {};
        if (input) {
            input.forEach((e: any) => tmp[e['Key']] = e['Value']);
        }
        return tmp;
    }

    private static makeSettingsMap(items: SettingItem[]): Map<string, any> {
        let m: Map<string, any> = new Map();
        for (let item of items) {
            m.set(item.Key, item.Value);
        }
        return m;
    }

    private static setHtmlLangAttribute(lang: string): void {
        document.querySelector('html').setAttribute('lang', lang);
    }

    private setCurrentServerUrl(): void {
        sessionStorage.setItem('currentServerUrl', this.settings.currentServerUrl)
    }

}

export enum AvailabilitySetting{
    onlyText='onlyText',
    onlyNumber='onlyNumber',
    both='both'
}