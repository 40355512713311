import {Component} from "@angular/core";
import {ArticleBriefSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {ArticleService} from "./article.service";
import {SettingsService} from "../../services/settings.service";
import {DomSanitizer} from "@angular/platform-browser";
import {Subject} from "rxjs";

@Component({
    selector:'cmp-articles-top-news',
    templateUrl: '../../tpl/article-top-news.html'
})

export class ArticleTopNewsComponent extends Translatable {

    articles: Array<ArticleBriefSelector>;
    imagePathPrefix: string;
    ngUnsubscribe: Subject<any> = new Subject<any>();


    constructor(public dataSvc: DataService, private artSvc: ArticleService, seSvc: SettingsService,
                private sanitizer: DomSanitizer) {
        super(dataSvc, seSvc);

        this.imagePathPrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/artnw/images/';
    }

    private sanitizeArticles(input: Array<ArticleBriefSelector>): Array<ArticleBriefSelector> {
        if(!input||!input.length){
            return null;
        }

        return input.map(a=>this.sanitizeArticle(a));
    }

    private sanitizeArticle(input: ArticleBriefSelector): ArticleBriefSelector {
        const tmp = input;
        if (tmp.annotation) tmp.annotation = this.sanitizer.bypassSecurityTrustHtml(<string>(tmp.annotation));
        return tmp;
    }

    ngOnInit(): void {
        this.getData();
    }

    private getData() {
        this.artSvc.getTopNews()
            .subscribe((res) => {
                  this.articles= this.sanitizeArticles(res);
              });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
