/**
 * @description
 * Producers lis route.
 */
import {Component} from '@angular/core';
import {Culture, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {GeneralDialogRef} from "../general-dialog/common";
import {BsLocaleService} from "ngx-bootstrap";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {dateValidator} from "../../helpers/validators.helper";
import {ScheduleModalService} from "./services/schedule-modal.service";



@Component({
    templateUrl: '../../tpl/schedule-modal.html',
    selector: 'cmp-schedule-modal'
})

export class ScheduleModalComponent extends Translatable {

    locale = 'cs';
    culture: Culture;
    dateForm: FormControl;

    constructor(public dataSvc: DataService
        , public seSvc: SettingsService
        , private bsLocale: BsLocaleService
        , private dialogRef: GeneralDialogRef
        , private scheduleModalSvc: ScheduleModalService
        , private fb: FormBuilder) {
        super(dataSvc, seSvc);

        this.bsLocale.use(this.locale);
        this.dateForm = this.fb.control(null, [Validators.required, dateValidator])
    }

    SetPlan(): void {
        if (this.dateForm.valid) {
            this.scheduleModalSvc.OnPlanWrite.emit(this.dateForm.value);
            this.dialogRef.close();
        }
    }
}