import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NoAutocompleteDirective} from "./no-autocomplete.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        NoAutocompleteDirective
    ],
    exports: [
        NoAutocompleteDirective
    ]
})

export class UiDirectivesModule {}