import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NewsLetterRegistrationComponent} from "./newsletter-registration.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule
    ],
    declarations: [
        NewsLetterRegistrationComponent

    ],
    exports: [
        NewsLetterRegistrationComponent

    ]
})

export class NewsLetterRegistrationModule {
}
