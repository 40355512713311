/**
 * @description
 * Producers lis route.
 */
import {Component} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {GeneralDialogRef} from "../general-dialog/common";
import {GeneralConfirmModalService} from "./services/general-confirm-modal.service";


@Component({
    templateUrl: '../../tpl/general-confirm-modal.html',
    selector: 'cmp-general-confirm-modal'
})

export class GeneralConfirmModalComponent extends Translatable {

    constructor(public dataSvc: DataService
        , public seSvc: SettingsService
        , private dialogRef: GeneralDialogRef
        , public generalConfirmModalSvc: GeneralConfirmModalService) {
        super(dataSvc, seSvc);

    }

    ChooseOption(confirm: boolean): void {
        this.generalConfirmModalSvc.OnConfirm.emit(confirm);
        this.dialogRef.close();
    }
}