import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {CartChangeModalComponent} from "./cart-change-modal.component";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";

@NgModule({
    imports: [
        CommonModule,
        ImageCacheBustModule,
        RouterModule,
        GeneralDialogModule
    ],
    declarations: [CartChangeModalComponent],
    exports: [CartChangeModalComponent],
    entryComponents:[CartChangeModalComponent]
})

export class CartChangeModalModule {}
