/**
 * Module for full article page route.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {PaginationModule} from 'ngx-bootstrap';
import {FormsModule} from "@angular/forms";
import {BreadcrumbsModule} from "../breadcrumbs/breadcrumbs.module";
import {ArticleListNewsIndexComponent} from "./article-list-news-index.component";
import {ArticleMainMenuComponent} from "./article-main-menu.component";
import {RouterModule} from "@angular/router";
import {ArticleService} from "./article.service";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {ArticleTopNewsComponent} from "./article-top-news.component";


@NgModule({
    imports: [
        BreadcrumbsModule,
        CommonModule,
        FormsModule,
        ImageCacheBustModule,
        RouterModule,
        PaginationModule.forRoot()
    ],
    declarations: [
        ArticleListNewsIndexComponent,
        ArticleMainMenuComponent,
        ArticleTopNewsComponent
    ],
    exports: [
        ArticleListNewsIndexComponent,
        ArticleMainMenuComponent,
        ArticleTopNewsComponent
    ],
    providers: [
        ArticleService
    ]
})

export class ArticleModule {
}
