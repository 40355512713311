import {Injectable} from "@angular/core";
import {loadFromLocalStorage, removeFromLocalStorage, saveToLocalStorage} from "../../helpers/cookie.helper";

@Injectable()
export class CartTokenService {

    constructor() {
        this._token = loadFromLocalStorage(this._tokenLocalStorageKey);
    }

    private _tokenLocalStorageKey: string = 'cartToken';
    private _token: string;

    public saveCartToken(cartToken: string): void {
        this._token = cartToken;
        if (cartToken) {
            saveToLocalStorage(this._tokenLocalStorageKey, cartToken);
        }
        else {
            removeFromLocalStorage(this._tokenLocalStorageKey);
            removeFromLocalStorage('order');
        }
    }

    public getCartToken(): string {
        return this._token;
    }

}
