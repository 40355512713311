/**
 * Created by jiri.kopel on 06.03.2019.
 */
import {Component, ElementRef, ViewChild} from "@angular/core";
import {SearchSelector, Translatable, SearchRequest} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {HttpClient} from "@angular/common/http";
import {fromEvent, Subject} from "rxjs";
import {map, filter, debounceTime, switchMap, takeUntil} from "rxjs/operators";
import {Router} from "@angular/router";
import {SearchService} from "../../services/search.service";


declare let $: any;

@Component({
    selector: 'cmp-flex-suggest',
    templateUrl: '../../tpl/flex-suggest.html'
})

export class FlexSuggestComponent extends Translatable {

    @ViewChild('phrase') phrase: ElementRef;

    searchBoxVisible: boolean = false;
    suggestVisible: boolean = false;
    suggestResult: SearchSelector;
    enterFired: Subject<any> = new Subject<any>();
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, seSvc: SettingsService, private http: HttpClient, private router: Router,
                private searchSvc: SearchService) {
        super(dataSvc, seSvc);

    }

    ngAfterViewInit(): void {
        this.bindSuggest();
        addEventListener('click', () => {
            this.suggestVisible = false;
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.enterFired.complete();
    }

    private bindSuggest(): void {
        const searchBox = this.phrase.nativeElement;
        fromEvent(searchBox, 'input')
            .pipe(
                map((e: any) => e.target.value),
                filter(text => text.length > 2),
                debounceTime(333),
                switchMap((s: string) => this.getSuggest(s)),
                takeUntil(this.enterFired),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((res: SearchSelector) => {
                this.suggestResult = res;
                this.suggestVisible = true;
            });
    }

    searchKeyUp(evt: KeyboardEvent) {
        if (evt.key === 'Escape') {
            this.suggestVisible = false;
        }
    }

    search(phrase: string): void {
        if (phrase.length == 0) return;
        this.phrase.nativeElement.value = '';
        this.suggestVisible = false;
        this.router.navigateByUrl(`/vysledky-vyhledavani?q=${phrase}`)
    }

    searchKeyPress(evt: KeyboardEvent, phrase: string): void {
        this.searchSvc.fireSearchInputChanged(phrase);

        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            this.search(phrase);
            this.suggestVisible = false;
            this.enterFired.next();
            this.bindSuggest();
            return;
        }
    }

    onFormSubmit(): boolean {
        return false;
    }

    getSuggest(phrase: string): any {
        let request: SearchRequest = {
            phrase: decodeURI(phrase),
            pageIndex: 1,
            pageSize: 5,
            forSuggest: true
        };
        return this.http.post(`api/search`, request);
    }

    public toggleSearch(): void {
        if (!this.searchBoxVisible) {
            $('.search').addClass("search--active");
            this.searchBoxVisible = true;
        }
        else {
            $('.search').removeClass("search--active");
            this.searchBoxVisible = false;
        }
    }

}
