import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {WritersOnlyDirective} from "./writers-only.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [WritersOnlyDirective],
    exports: [WritersOnlyDirective]
})

export class RightsHelpersModule {}