import {FormControl} from "@angular/forms";

export function dateValidator(control: FormControl) {

    let date = control.value;
    let today = new Date();

    if (date) date.setHours(0,0,0,0);
    today.setHours(0,0,0,0);

    if (date && date < today) {
        return {
            DateError:'date is less than current date'
        };
    }
    return null;
}