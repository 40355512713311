import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";
import {SettingsService} from "../services/settings.service";

@Directive({
    selector: 'img'
})

export class ImageCacheBustDirective implements OnInit {


    private _src: string;
    @Input() set src(value: string) {
        const sep = /\?/.test(value) ? '&' : '?';
        this._src = value;
        this._src += `${sep}cbk=${this.seSvc.cacheBustKey}`;
        this.renderer.setAttribute(this.elRef.nativeElement, 'src', this._src);
    }

    get src(): string {
        return this._src;
    }

    constructor(private elRef: ElementRef, private renderer: Renderer2, private seSvc: SettingsService) {}

    ngOnInit(): void {

    }
}