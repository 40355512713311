import {Component, ElementRef, HostListener, Input, Renderer2} from "@angular/core";
import {CategoryItemSelector, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {MenuBehavior} from "./common";
import {MenuService} from "../../services/menu.service";


@Component({
    selector: 'cmp-menu',
    templateUrl: '../../tpl/menu.html'
})

export class MenuComponent extends Translatable {

    @Input() menuBehavior: MenuBehavior = MenuBehavior.default;

    private completeCatTreeLoaded: boolean = false;
    private menuActive: boolean = false;
    private ngUnsubscribe: Subject<any> = new Subject<any>();

    categories: Array<CategoryItemSelector>;
    menuImagePathPrefix: string;

    private _js_menu_element: any;
    private get js_menu_element(): any {
        if (!this._js_menu_element) {
            this._js_menu_element = this.elementRef.nativeElement.querySelector('.js-menu');
        }

        return this._js_menu_element;
    }

    private _menu_element: any;
    private get menu_element(): any {
        if (!this._menu_element) {
            this._menu_element = this.elementRef.nativeElement.querySelector('.menu');
        }

        return this._menu_element;
    }

    private _responsive_menu_element: any;
    private get responsive_menu_element(): any {
        if (!this._responsive_menu_element) {
            this._responsive_menu_element = this.elementRef.nativeElement.querySelector('.responsive-menu');
        }

        return this._responsive_menu_element;
    }


    constructor(public dataSvc: DataService, public seSvc: SettingsService, private http: HttpClient,
                private elementRef: ElementRef, private renderer: Renderer2, private menuSvc: MenuService) {
        super(dataSvc, seSvc);
        this.menuImagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/cattop/images/cathome/';

        this.menuSvc.toggleMenu
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.toggleMenu();
            });
    }

    ngOnInit(): void {
        this.getCategories();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private getCategories(): void {
        this.http.get<CategoryItemSelector[]>(`api/category/getRootCategories`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.categories = res;
                this.getAllTreeLazy();
            });
    }

    private getAllTreeLazy(): void {
        this.http.get<CategoryItemSelector[]>(`api/category`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.categories = res;
                this.completeCatTreeLoaded = true;
            });
    }

    rootCategoryMouseEnter(catId: number): void {
        /**
         * NOTE:
         * no need for other logic here, if cache is ON and proper "cache-control" HTTP header is set,
         * the request for the same category simply doesn't get fired twice for given cache interval
         */
        if (this.completeCatTreeLoaded) return;

        this.http.get<CategoryItemSelector[]>(`api/category/getRootCategorySubTree/${catId}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                for (let i = 0; i < this.categories.length; i++) {
                    if (this.categories[i].id === catId) {
                        this.categories[i].subcategories = res;
                        break;
                    }
                }
            });
    }

    toggleMenu(): void {
        if (!this.menuActive) {
            if (this.menu_element) {
                this.renderer.addClass(this.menu_element, "menu--active");
            }
            if (this.responsive_menu_element) {
                this.renderer.addClass(this.responsive_menu_element, "responsive-menu--active");
            }
            this.menuActive = true;
        } else {
            if (this.menu_element) {
                this.renderer.removeClass(this.menu_element, "menu--active");
            }
            if (this.responsive_menu_element) {
                this.renderer.removeClass(this.responsive_menu_element, "responsive-menu--active");
            }


            this.menuActive = false;
        }
    }


    @HostListener('document:click', ['$event'])
    handleClick() {
        if (this.menuBehavior == MenuBehavior.openAndFixedOnIndex && this.dataSvc.pageType == 'index') {
            return;
        }
        if (this.js_menu_element) {
            this.renderer.removeClass(this.js_menu_element, 'menu--active');
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(/*event: KeyboardEvent*/) {

        if (this.menuBehavior == MenuBehavior.openAndFixedOnIndex && this.dataSvc.pageType == 'index') {
            return;
        }
        if (this.js_menu_element) {
            this.renderer.removeClass(this.js_menu_element, 'menu--active');
        }
    }

    toggleActive(event: any) {
        event.stopPropagation();

        if (this.js_menu_element) {
            if (this.js_menu_element.classList.contains('menu--active')) {
                this.renderer.removeClass(this.js_menu_element, 'menu--active');
            } else {
                this.renderer.addClass(this.js_menu_element, 'menu--active');
            }
        }

    }
}
