
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductSearchModule} from "../product-search/product-search.module";
import {CategoryFilterModule} from "../category-filter/category-filter.module";
import {BsLocaleService} from "ngx-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PricelistService} from "../pricelist/services/pricelist.service";
import {OrganizationService} from "../organization/services/organization.service";
import {GrowlModule} from "../growl/growl.module";
import {ValidationModule} from "../validation/validation.module";
import {GeneralConfirmModalComponent} from "./general-confirm-modal.component";



@NgModule({
    imports: [
        CommonModule,
        ProductSearchModule,
        CategoryFilterModule,
        ReactiveFormsModule,
        FormsModule,
        GrowlModule,
        ValidationModule
    ],

    declarations: [GeneralConfirmModalComponent],
    providers: [PricelistService, OrganizationService,BsLocaleService]
})

export class GeneralConfirmModalModule {
}