
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule} from "@angular/forms";
import {CategoryFilterComponent} from "./category-filter.component";
import {CategoryFilterService} from "./Services/category-filter.service";
import {TreeModule} from "primeng/primeng";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TreeModule
    ],

    declarations: [
        CategoryFilterComponent
    ],
    exports: [
        CategoryFilterComponent
    ],
    providers: [CategoryFilterService]
})

export class CategoryFilterModule {
}
