/**
 * @description
 * Producers lis route.
 */
import {Component} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {IOrganization} from "../organization/common";
import {GeneralDialogRef} from "../general-dialog/common";
import {CopySellingPricesModalService} from "./services/copy-selling-prices-modal.service";


@Component({
    templateUrl: '../../tpl/copy-selling-prices-modal.html',
    selector: 'cmp-copy-selling-price-modal'
})

export class CopySellingPricesModalComponent extends Translatable {

    organizationForm: FormGroup;
    organizations: IOrganization[] = [];

    constructor(public dataSvc: DataService
        , public seSvc: SettingsService
        , private dialogRef: GeneralDialogRef
        , private copySellingModalSvc: CopySellingPricesModalService
        , private fb: FormBuilder) {
        super(dataSvc, seSvc);

    }

    ngOnInit(): void {

            this.organizations = this.copySellingModalSvc.organizations;
            this.CreateForm();
        ;
    }

    CreateForm(): void {
        let organizationsFormArray: FormArray = new FormArray([]);
        this.organizations.forEach(o => {

            let fg: FormGroup = this.fb.group({
                Id: [o.Id],
                Name: [o.Name],
                PricelistId: [o.PricelistId],
                Checked: []
            });
            organizationsFormArray.push(fg);
        })
        this.organizationForm = this.fb.group({
            Organizations: organizationsFormArray
        });
    }

    Copy(): void {
        let organizationIds = this.organizationForm.get('Organizations').value
            .filter((f:any) => f.Checked)
            .map((m:any) => m.PricelistId);

        this.copySellingModalSvc.OnCopy.emit(organizationIds);
        this.dialogRef.close();
    }
}