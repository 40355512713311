/**
 * Serves article data, can be injected when needed (child injector).
 */
import {Injectable, Input} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ArticleBaseWithChildrenSelector, ArticleBriefSelector, ArticleSelector} from "../../interfaces/general";
import {AsyncSubject, Observable} from "rxjs";


@Injectable()
export class ArticleService {

    @Input() childIndex: number = -1;

    private footerContainerArticleCalled: boolean = false;
    private _asyncSubject: AsyncSubject<ArticleBaseWithChildrenSelector> = new AsyncSubject<ArticleBaseWithChildrenSelector>();

    constructor (private http: HttpClient) {
    }

    getArticle(id: string, seoUrl:string): any {
        return this.http.get<ArticleSelector>(`api/article/${id}/${seoUrl}`);
    }

    getBreadcrumbs(id: string):any{
        return this.http.get(`api/article/breadcrumb/${id}`);
    }

    getTopNews():Observable<Array<ArticleBriefSelector>>{
        return this.http.get<Array<ArticleBriefSelector>>('api/article/top-news');
    }


    getFooterArticles(): Observable<ArticleBaseWithChildrenSelector> {
        //https://stackoverflow.com/questions/33675155/creating-and-returning-observable-from-angular-2-service
        if (!this.footerContainerArticleCalled) {
            this.footerContainerArticleCalled=true;
            let sub = this.http.get<ArticleBaseWithChildrenSelector>(`api/article/getFooterMenuLinks`)
                .subscribe(
                    (res) => {
                        this._asyncSubject.next(res);
                        this._asyncSubject.complete();
                        sub.unsubscribe();
                    }
                );
        }
        return this._asyncSubject;
    }


}
