import {Component, Input} from "@angular/core";

@Component({
    selector: 'cmp-preloader',
    templateUrl: '../../tpl/preloader.html'

})

export class PreloaderComponent {

    @Input() loading: boolean = false;

    constructor () {}
}
