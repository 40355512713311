import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest} from "@angular/common/http";
import {Observable, EMPTY} from "rxjs";
import {Injectable} from "@angular/core";
import {IntegrationService} from "./integration.service";
import {HttpRegionalSettings} from "../interfaces/general";
import {CredentialStorage} from "../services/credential-storage.service";
import {CartTokenService} from "../modules/cart/cart-token.service";
import { tap } from 'rxjs/operators';
import {Router} from "@angular/router";
import {isRouteSecured} from "../helpers/string.helper";


@Injectable()
export class MainInterceptor implements HttpInterceptor {

    private settings: HttpRegionalSettings;

    constructor(private iSvc: IntegrationService, private cartTokenSvc: CartTokenService, private router: Router) {
        this.settings = this.iSvc.httpRegional;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!/api\/preauth/.test(req.url) && !/api\/lang/.test(req.url) && !this.settings.comAllowed) return EMPTY;

        this.settings = this.iSvc.httpRegional;
        let newUrl;

        const authHeadersBody: string = CredentialStorage.getStoredJwTokenBody();

        if (req.method === 'GET') {
            newUrl = /\?/.test(req.url)
                ? `${req.url}&cu=${this.settings.cultureId}&cy=${this.settings.currencyId}&lin=${CredentialStorage.userLoggedIn}`
                : `${req.url}?cu=${this.settings.cultureId}&cy=${this.settings.currencyId}&lin=${CredentialStorage.userLoggedIn}`;
        } else newUrl = req.url;

        let cartToken = this.cartTokenSvc.getCartToken();

        let duplicate;
        if (authHeadersBody) {
            duplicate = req.clone({
                headers: req.headers
                    .append('F-CC', `cu:${this.settings.cultureId},cy:${this.settings.currencyId}`)
                    .append('Authorization', authHeadersBody)
                    .append('cart-token', cartToken || ''),
                url: newUrl
            });
        } else {
            duplicate = req.clone({
                headers: req.headers
                    .append('F-CC', `cu:${this.settings.cultureId},cy:${this.settings.currencyId}`)
                    .append('cart-token', cartToken || ''),
                url: newUrl
            });
        }
        return next.handle(duplicate)
            .pipe(
                tap(() => {},
                    (err: any) => {
                        /**
                         * server side kick-off scenario
                         * this is a safety catch when for some reason the client did not react to token expiration
                         * but the server rejected the token and throws 403 back
                         */
                        if (!/api\/login/.test(req.url) && err instanceof HttpErrorResponse && (<HttpErrorResponse>err).status === 403) {
                            CredentialStorage.removeAuthInfo();

                            let fwd = this.router.url;
                            let url;

                            if (isRouteSecured(fwd)) {
                                url = fwd ? `/upozorneni?fwd=${fwd}` : '/upozorneni';
                            } else {
                                url = '/';
                            }

                            this.router.navigateByUrl(url)
                                .then(() => {
                                    location.reload();
                                });
                        }
                    })
                );
    }
}