/**
 * Created by jan.hroch on 27.06.2017.
 */
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Culture, SearchSelector, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";

@Component({
    selector: 'cmp-flex-suggest-list',
    templateUrl: '../../tpl/flex-suggest-list.html'
})

export class FlexSuggestListComponent extends Translatable {

    @Input() suggestResult: SearchSelector;
    @Output() queryHide: EventEmitter<boolean>;
    @Input() phrase: string;
    imagePathPrefix: string;
    culture: Culture;
    currencySymbol: string;

    constructor(dataSvc: DataService, seSvc: SettingsService) {
        super(dataSvc, seSvc);

        this.imagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/suggest/images/produkty/';
        this.queryHide = new EventEmitter();
        this.culture = this.seSvc.culture;
        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
    }

    linkClicked(): void {
        this.queryHide.emit(true);
    }

    onComponentClick(evt: any): void {
        evt.stopPropagation();
    }

}
