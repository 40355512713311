/**
 * Panel providing filtering by product flags, see the {@link SharedProductSettings} class, the {@Link Flags} property.
 * For instance flags are preserved across category navigation and cultureId changes
 */
import {Component, EventEmitter, Output} from '@angular/core';
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {CategoryFilterService} from "./Services/category-filter.service";


@Component({
    selector: 'cmp-category-filter',
    templateUrl: '../../tpl/category-filter.html'
})

export class CategoryFilterComponent extends Translatable {

    @Output() onSelectCategory: EventEmitter<number>;

    categories: any[];
    selectedCategory: string = null;
    isSelectedCategory: boolean = false;
    open: boolean = false;

    constructor(public dataSvc: DataService, public seSvc: SettingsService, public categoryFilterSvc: CategoryFilterService) {
        super(dataSvc, seSvc);

        this.onSelectCategory = new EventEmitter();

    }

    ngOnInit(): void {
        this.categoryFilterSvc.GetCategoriesFilter()
            .subscribe(res => {
                this.categories = res;


            });
    }

    SelectCategory(event: any): void {
        this.onSelectCategory.emit(event.node.data);
        this.isSelectedCategory = true;
        this.selectedCategory = event.node.label;
    }

    CancelFilter(): void {
        this.onSelectCategory.emit(null);
        this.isSelectedCategory = false;
        this.selectedCategory = null;
    }

    Open(): void {
        this.open = !this.open;
    }
}