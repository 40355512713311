import {EventEmitter, Injectable} from "@angular/core";
import {IOrganization} from "../../organization/common";



@Injectable()
export class CopySellingPricesModalService {
    constructor(
    ) {
    }

    OnCopy: EventEmitter<number[]> = new EventEmitter<number[]>();
    organizations: IOrganization[] = [];

}