import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductSearchModule} from "../product-search/product-search.module";
import {CategoryFilterModule} from "../category-filter/category-filter.module";
import {BsDatepickerModule, BsLocaleService} from "ngx-bootstrap";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GrowlModule} from "../growl/growl.module";
import {ValidationModule} from "../validation/validation.module";
import {ScheduleModalComponent} from "./schedule-modal.component";

@NgModule({
    imports: [
        CommonModule,
        ProductSearchModule,
        CategoryFilterModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        FormsModule,
        GrowlModule,
        ValidationModule
    ],
    declarations: [ScheduleModalComponent],
    providers: [BsLocaleService]
})

export class ScheduleModalModule {
}