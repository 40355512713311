import {Component, Input} from "@angular/core";

@Component({
    selector: 'cmp-val-msg',
    templateUrl: '../../tpl/validation-msg.html'
})

export class ValidatorMessage {

    @Input() msg: string;

}